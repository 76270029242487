<template>
  <div class="p-6 loading-table" v-if="showPndingAjaxLoad">
    <spinner-color />
  </div>
  <div v-else>
    <data-table-client-cost :data="this.$store.state.dataList.costs" :header="header" :body="body" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import DataTableClientCost from '../components/table-components/DataTableClientCost.vue';
import SpinnerColor from '@/views/components/table-components/SpinnerColor2.vue';
import globalMixin from '@/mixins/mixins.js';

export default {
  mixins: [globalMixin],
  components: {
    DataTableClientCost,
    SpinnerColor,
  },
  data() {
    return {
      header: [
        // { head: 'from', key: 'from_name' },
        { head: 'governorate', key: 'to_name' },
        { head: 'deliveryCost', key: 'delivery_cost' },
        { head: 'deliveryCostBackSender', key: 'delivery_cost_back_sender' },
        { head: 'deliveryCostBackReceiver', key: 'delivery_cost_back_receiver' },
        { head: 'backCostWithoutDeliveryCost', key: 'back_cost_without_delivery_cost' },
        { head: 'backCostSender', key: 'back_cost_sender' },
        { head: 'backCostReceiver', key: 'back_cost_receiver' },
        { head: 'partialBackCost', key: 'partial_back_cost' },
        { head: 'deliveryTime', key: 'delivery_time' },
      ],
      body: [
        // 'from_name',
        'to_name',
        'delivery_cost',
        'delivery_cost_back_sender',
        'delivery_cost_back_receiver',
        'back_cost_without_delivery_cost',
        'back_cost_sender',
        'back_cost_receiver',
        'partial_back_cost',
        'delivery_time',
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions('dataList', ['fetchDataClientCosts']),
  },
  async created() {
    let credentials = {
      pageNum: null,
      itemsPerPage: 10,
    };
    this.fetchDataClientCosts(credentials);
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_loadingTable.scss';
</style>
